.about__contents {
  width: 70%;
  margin: auto;
}

.about__contents .heading h3 {
  text-align: center;
}

.about__contents p {
  margin-bottom: 2rem;
  line-height: 25px;
  text-align: center;
}

.about .banner {
  height: 90vh;
}

.about .banner img {
  height: 100%;
  object-fit: cover;
}

@media (max-width: 550px) {
  .about .banner {
    height: 40vh;
  }
}
