.services__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/images/services.webp");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 50% 50%/0 0 100% 100%; */
}

.services__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
}

.services {
  width: 95%;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.services__contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.services .subtext {
  text-align: center;
  width: 70%;
  margin: auto;
  color: var(--banner-bg);
}

.services .heading {
  text-align: center;
  margin: 3rem auto;
}

@media (max-width: 550px) {
  .services__contents {
    display: block;
  }

  .services .subtext {
    width: 100%;
    font-size: 14px;
  }

  .services__banner {
    height: 40vh;
    border-radius: 0;
  }

  .services__header {
    font-size: 15px;
  }
}
