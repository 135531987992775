.offer {
  position: relative;
  width: 100%;
}

.offer__contents {
  width: 100%;
  height: 70vh;
}

.offer__contents img {
  width: 100%;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.offer__contents__details {
  position: absolute;
  bottom: 2rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.675);
  text-transform: uppercase;
  width: 80%;
  text-align: left;
  z-index: 99999;
}

.offer__contents__details h3 {
  color: white;
}

.offer__contents__details a {
  color: white;
  text-decoration: none;
  display: flex;
  font-size: 14px;
}

.hover__contents {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000000b8;
}

.offer:hover .hover__contents {
  opacity: 1;
}

.hover__contents p {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.hover__contents h3 {
  text-align: center;
  margin-top: 5rem;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  border-bottom: 3px solid white;
}

@media (max-width: 550px) {
  .hover__contents {
    opacity: 1;
  }
}
