.team__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/images/team.webp");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0 0 30% 30%/0 0 50% 50%;
}

.team__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
  padding: 0 10px;
}
.team {
  text-align: center;
  width: 50%;
  margin: auto;
}

.team img {
  width: 100%;
  object-fit: cover;
  margin: 10rem auto;
}

.licence__ceo {
  width: 80%;
  height: 80vh;
  margin: 1rem auto;
  object-fit: contain;
}

.licence__ceo p {
  font-size: 20px;
  font-weight: bold;
}

.licence__ceo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 550px) {
  .team,
  .licence__ceo {
    width: 90%;
  }

  .team img {
    margin: 3rem auto;
  }

  .team__banner,
  .licence__ceo {
    height: 40vh;
  }

  .team__header {
    font-size: 0.9375rem; /* 15px converted to rem for scalability */
  }
}
