.review {
  width: 100%;
  margin: auto;
  background-color: var(--review-bg);
  height: 30vh;
  padding: 2rem 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.review__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__header .img {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review__header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.review__header .google img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.review__header .name {
  font-size: 14px;
}

.review__header .name h4 {
  font-weight: bolder;
  text-transform: capitalize;
}

.review__header .name p {
  color: var(--heading-text);
}

.review__rate .MuiSvgIcon-root {
  color: var(--gold);
  margin: 1rem auto;
}

.review__comment {
  font-size: 12px;
  overflow-y: scroll;
  height: 50%;
  width: 90%;
}
