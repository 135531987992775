.heading {
  text-align: center;
}

.heading h3,
.heading h2 {
  color: var(--gold);
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 0;
}

.heading p {
  font-size: 25px;
  font-weight: bold;
  text-transform: capitalize;
}

@media (max-width: 550px) {
  .heading h3 {
    font-size: 20px;
  }

  .heading p {
    font-size: 16px;
  }
}
