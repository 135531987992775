.home__video {
  height: 95vh;
  width: 100%;
}

.home__video video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.home__video__text {
  position: absolute;
  bottom: 0.1rem;
  background-color: var(--banner-bg);
  padding: 1rem 2rem;
  text-transform: capitalize;
  color: white;
  text-align: center;
}

.home__video__text a {
  text-decoration: none;
}
.home__video__text h1 {
  width: 80%;
  margin: auto;
  font-size: 35px;
}

.home__hero {
  margin-top: 5rem;
  text-align: center;
  width: 95%;
  margin: auto;
}

.home__hero h3 {
  font-weight: bolder;
  margin-bottom: 0.5rem;
}

.home__hero__contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.home__hero__contents__logo {
  width: 40%;
}

.home__hero__contents__text {
  width: 60%;
}

.home__hero__contents__logo img {
  width: 100%;
}

.home__hero p {
  font-weight: lighter;
}

.home__offers__container,
.home__projects__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.projects.home {
  width: 100%;
  justify-content: space-between;
}

.home__offers__container .offer__content,
.home__projects__container .projects__content {
  width: 33%;
  margin-bottom: 1rem;
}

.projects.page {
  width: 33%;
}

.home__portfolio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--grey);
  padding: 5rem;
}

.home__portfolio > div {
  width: 50%;
}

.home__portfolio a {
  text-decoration: none;
}

.home__portfolio .button {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

.home__portfolio__text {
  margin-top: 3rem;
}

.home__portfolio__text h3 {
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 1rem;
}

.home__clients {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin: auto;
}

.home__reviews {
  display: flex;
  width: 90%;
  margin: auto;
  align-items: center;
}

.home__reviews .home__reviews__rate {
  display: flex;
  width: 100%;
}

.swiper {
  width: 100%;
}

.home__reviews .home__reviews__rate__img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.home__reviews__rate__details h3 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bolder;
}

.home__reviews__rate__details p {
  display: flex;
  align-items: center;
  color: var(--gold);
}

.home__reviews__rate__details p .MuiSvgIcon-root {
  font-size: 16px;
}

.home__reviews__rate .desc img {
  width: 50%;
}

.home__reviews__rate .desc p,
.review__us p {
  color: black;
}

.review__us {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: rgb(33, 33, 93);
  padding: 0.3rem;
  border-radius: 10px;
  width: 100%;
}

.review__us a {
  text-decoration: none;
  color: white;
}

.review__us img {
  width: 30px;
}

.home__logos__heading {
  text-align: center;
  margin: 3rem auto;
}

.home__logos__main {
  width: 75%;
  margin: auto;
}
.home__logos__main div img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 3rem;
}

.projects {
  position: relative;
  width: 100%;
}

.projects__contents {
  width: 100%;
  height: 70vh;
}

.projects__contents img {
  width: 100%;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projects__hover__contents {
  position: absolute;
  color: white;
  height: 95%;
  width: 95%;
  padding: 5px 0;
  text-align: left;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid white;
}

.projects__hover__contents > div {
  position: absolute;
  bottom: 2rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.675);
  text-transform: uppercase;
  width: 80%;
}

.projects__hover__contents h3 {
  font-size: 25px;
  text-transform: capitalize;
}

.main__text {
  text-align: left;
  margin-top: 10px;
  font-size: 20px;
  text-transform: uppercase;
}

.projects__hover__contents #desc {
  border-bottom: 1px solid white;
  padding-bottom: 2rem;
}

.projects__hover__contents a {
  color: white;
  text-decoration: none;
  display: flex;
  font-size: 14px;
}

.projects__hover__contents a .MuiSvgIcon-root {
  font-size: 12px;
}

.projects__hover__contents #beauty {
  font-size: 12px;
}

.home a {
  text-decoration: none;
}

@media (max-width: 550px) {
  .home__video {
    height: 50vh;
  }

  .home__video__text {
    top: 10rem;
    height: fit-content;
  }

  .home__hero__contents {
    display: block;
  }

  .home__hero__contents__logo,
  .home__hero__contents__text {
    width: 100%;
  }

  .home__hero__contents__text .text {
    width: 100%;
    font-size: 20px;
  }

  .home__hero__contents__text h3 {
    font-size: 14px;
  }

  .home__hero__contents__text p {
    font-size: 14px;
  }

  .home__offers .heading h3 {
    margin-bottom: 0;
  }

  .home__offers__container,
  .home__projects__container {
    display: block;
  }

  .home__offers__container .offer__content,
  .home__projects__container .projects__content {
    width: 100%;
  }

  .home__logos__heading {
    margin: 1rem auto;
  }

  .home__logos__main {
    width: 90%;
    margin: auto;
  }

  .home__reviews {
    display: block;
  }

  .home__reviews .home__reviews__rate {
    margin-bottom: 3rem;
  }

  .home__clients {
    display: block;
  }

  .home__portfolio {
    display: block;
    padding: 1rem;
  }

  .home__portfolio > div {
    width: 100%;
  }

  .home__portfolio .button {
    font-size: 12px;
    letter-spacing: 0;
    margin-bottom: 2rem;
  }

  .home__portfolio__text p {
    font-size: 14px;
  }
}
