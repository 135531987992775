.single {
  width: 90%;
  margin: auto;
}

.single .single__swiper {
  width: 100%;
  height: 50vh;
  margin-bottom: 3rem;
}

.single .single__swiper img {
  width: 80%;
  height: 100%;
  object-fit: cover;
}

.excerpts__more {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
  margin-bottom: 2rem;
}

.excerpts__more .heading {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.excerpts__more__others {
  border: solid black 0.2px;
  padding: 1rem;
}

.excerpts__more__others > div {
  margin-bottom: 2rem;
}

.excerpts__more__others > div .header {
  font-weight: bold;
}

@media (max-width: 550px) {
  .single .single__swiper img {
    width: 100%;
  }
}
