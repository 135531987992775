.testimonials {
  margin: 3rem auto;
}

.testimonials__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/images/review-bg.webp");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 50% 50%/0 0 100% 100%; */
}

.testimonials__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
}

.testimonials__heading {
  background-color: black;
  color: white;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.testimonials__heading h3 {
  padding: 1rem;
  font-size: 25px;
  text-transform: capitalize;
}

.testimonials__container {
  margin: 5rem auto;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
}

.testimonials__swiper {
  background-color: var(--review-bg);
  height: fit-content;
}

.testimonials__swiper p {
  text-align: center;
  margin-bottom: 2rem;
  line-height: 2rem;
}

.testimonials__swiper h3 {
  text-align: center;
  text-transform: capitalize;
}

.testimonials__comments {
  background-color: white;
  padding: 3rem 1rem;
}

@media (max-width: 550px) {
  .testimonials__banner {
    height: 40vh;
  }

  .testimonials__header {
    font-size: 15px;
  }
}
