.clients {
  width: 100%;
  text-align: center;
  margin: 1rem auto;
}

.clients__icon .MuiSvgIcon-root {
  font-size: 7rem;
  color: var(--gold);
}

.clients__text #number {
  font-size: 30px;
}

.clients__text #text {
  font-size: 20px;
  text-transform: capitalize;
  color: rgb(111, 111, 111);
}
