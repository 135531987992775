.authority__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/authority.webp");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 50% 50%/0 0 100% 100%; */
}

.authority__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
}

.authority {
  width: 90%;
  margin: auto;
}

.authority .heading {
  margin: 2rem auto;
}

.authority__content p {
  line-height: 30px;
  margin-bottom: 1rem;
  text-align: center;
}

@media (max-width: 550px) {
  .authority__banner {
    height: 40vh;
  }

  .authority h3 {
    text-align: center;
    margin: 1rem 0;
  }

  .authority__content p {
    font-size: 14px;
  }

  .authority__header {
    font-size: 15px;
  }
}
