.clients {
  margin: 2rem auto;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clients__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/images/clients.webp");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 50% 50%/0 0 100% 100%; */
}

.clients__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
}

.clients__container .heading {
  text-align: center;
}

.clients__main {
  width: 75%;
  margin: auto;
}
.clients div img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 3rem;
}

@media (max-width: 550px) {
  .clients {
    height: fit-content;
  }
}
