.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--header-nav-text);
  background-color: var(--header-nav-bg);
  position: sticky;
  top: 0;
  z-index: 9999999;
}

.nav__mobile {
  display: none;
}

.nav__logo img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
}

.nav__links ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav__links ul li {
  margin-right: 1rem;
}

.nav__links ul li a {
  text-decoration: none;
  text-transform: capitalize;
  color: rgb(53, 50, 50);
  font-weight: bold;
}

.nav__links ul li a:hover {
  border-bottom: 0.2rem solid var(--gold);
}

.nav__contact__icons a {
  text-decoration: none;
  color: rgb(53, 50, 50);
  font-size: 16px;
}

.nav__contact__icons p {
  margin-right: 2.5rem;
  margin-bottom: 0.5rem;
}

.nav__contact__icons p .MuiSvgIcon-root {
  font-size: 12px;
  color: var(--gold);
  margin-right: 0.5rem;
}

.nav__contact__icons__others a {
  text-decoration: none;
  color: rgb(53, 50, 50);
  margin: auto 1rem;
  font-weight: bold;
}

.nav__contact__icons__others a:hover,
.nav__contact__icons a:hover {
  color: var(--gold);
}

.nav__links #about,
.nav__links #services,
.nav__links #clients {
  display: flex;
  align-items: center;
  position: relative;
}

.nav__links .about__nav,
.nav__links .about__services,
.nav__links .about__clients {
  position: absolute;
  background-color: var(--review-bg);
  height: fit-content;
  top: 1.5rem;
  width: 10rem;
  padding: 1rem;
  border-radius: 10px;
  z-index: 999;
}

.nav__links .about__nav ul,
.about__services ul,
.about__clients ul {
  display: block;
}

.nav__links .about__nav ul li,
.nav__links .about__services ul li,
.nav__links .about__clients ul li {
  margin-bottom: 1rem;
  font-size: 14px;
  border-bottom: 0.2px solid var(--gold);
  padding-bottom: 5px;
}

.nav__links .about__nav ul li a:hover,
.nav__links .about__services ul li a:hover,
.nav__links .about__clients ul li a:hover {
  border: none;
  color: var(--gold);
}

.nav__contact__now {
  width: fit-content;
}

.nav__contact__now a {
  text-decoration: none;
}

.nav__contact__now .button {
  padding: 0.3rem 1rem;
  font-size: 14px;
}

.home__contact__btn {
  width: fit-content;
  /* padding: 0.5rem 1rem; */
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
  letter-spacing: 4px;
  animation: button 0.6s ease infinite alternate;
}

@keyframes button {
  from {
    border: solid rgb(254, 229, 195) 0.1rem;
  }

  to {
    border: solid var(--gold) 0.1rem;
  }
}

@media (max-width: 1024px) {
  .nav.desktop {
    display: none;
  }

  .nav__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 999999;
  }

  .nav__mobile img {
    width: 70px;
    height: 70px;
  }

  .toggle__menu {
    position: fixed;
    top: 0rem;
    left: 0;
    width: 70%;
    background-color: var(--header-nav-text);
    z-index: 999999999;
    height: 70vh;
    padding: 2rem 1rem;
    /* transition: all 2s ease; */
    animation: nav;
    animation-duration: 2s;
    overflow-y: scroll;
  }

  @keyframes nav {
    from {
      opacity: 0.8;
    }

    to {
      opacity: 1;
    }
  }

  .toggle__menu ul {
    list-style: none;
  }

  .toggle__menu ul a {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 2rem;
  }

  #about__mobile,
  #about__services,
  #about__clients {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
  }

  #about__mobile .MuiSvgIcon-root,
  #about__services .MuiSvgIcon-root,
  #about__clients .MuiSvgIcon-root {
    color: white;
    margin-left: 1rem;
  }

  .about__nav__mobile,
  .about__services__mobile,
  .about__clients__mobile {
    height: fit-content;
    top: 1.5rem;
    width: fit-content;
    padding: 1rem;
    border-radius: 10px;
    z-index: 999;
  }

  .about__nav__mobile ul,
  .about__services__mobile ul,
  .about__clients__mobile ul {
    display: block;
  }

  .about__nav__mobile ul li,
  .about__services__mobile ul li,
  .about__clients__mobile ul li {
    margin-bottom: 1rem;
    font-size: 14px;
    border-bottom: 0.2px solid var(--gold);
    padding-bottom: 5px;
  }

  .home__contact__btn {
    margin: 3rem auto;
  }

  .nav__contact__now__mobile a {
    text-decoration: none;
  }

  .nav__contact__now__mobile .button {
    font-size: 12px;
  }

  .nav__contact__icons a {
    text-decoration: none;
    color: white;
    font-size: 12px;
    margin-left: 5px;
  }

  .nav__contact__icons__others {
    margin-top: 1rem;
  }

  .nav__contact__icons__others a {
    color: white;
  }

  .nav__mobile {
    padding: 0 1rem;
  }
}
