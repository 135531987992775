.commercial__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/hospitality.webp");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 50% 50%/0 0 100% 100%; */
}

.commercial__lists.span {
  font-weight: bold;
}

.commercial {
  width: 90%;
  margin: auto;
}

.commercial .heading {
  margin: 2rem auto;
}

.commercial__content p {
  line-height: 30px;
  margin-bottom: 1rem;
  text-align: center;
}

.commercial__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
}

.commercial h3 {
  margin: 3rem 0;
}

.commercial li {
  line-height: 3rem;
}

.commercial__gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pic__gallery {
  width: 30%;
  height: 100%;
  position: relative;
}

.pic__gallery .pic img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.pic__logo img {
  position: absolute;
  width: 150px;
  height: 150px;
  object-fit: cover;
  bottom: 1rem;
  right: 0;
  opacity: 0.6;
}

@media (max-width: 550px) {
  .commercial__banner {
    height: 40vh;
  }

  .commercial h3 {
    text-align: center;
    margin: 1rem 0;
  }

  .commercial__content p {
    font-size: 14px;
  }

  .commercial__header {
    font-size: 15px;
  }

  .commercial__gallery {
    display: block;
  }

  .pic__gallery {
    width: 100%;
  }
}
