.button p,
.home__contact__btn p {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  border: solid 2px var(--gold);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  /* color: white; */
  transition: 0.5s;
  transition-delay: 0s;
}

.button p:hover,
.home__contact__btn p:hover {
  color: black;
  transition-delay: 1.5s;
  box-shadow: 0 0 10px var(--gold), 0 0 20px var(--gold), 0 0 40px var(--gold),
    0 0 60px var(--gold), 0 0 80px var(--gold), 0 0 100px var(--gold);
}

.button p::before,
.home__contact__btn p::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: var(--btn-gold);
  box-shadow: 5px -8px 0 var(--gold), 5px 8px 0 var(--gold);
  transition: width 0.5s, left 0.5s, height 0.5s, box-shadow 0.5s;
  transition-delay: 1s, 0.5s, 0s, 0s;
}

.button p:hover::before,
.home__contact__btn p:hover::before {
  width: 60%;
  height: 100%;
  left: -2px;
  box-shadow: 5px 0 0 var(--gold), 5px 0 0 var(--gold);
  transition-delay: 0s, 0.5s, 1s, 1s;
}

.button p::after,
.home__contact__btn p::after {
  content: "";
  position: absolute;
  right: -20px;
  /* right: 50%; */
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: var(--btn-gold);
  box-shadow: 5px -8px 0 var(--gold), 5px 8px 0 var(--gold);
  transition: width 0.5s, left 0.5s, height 0.5s, box-shadow 0.5s;
  transition-delay: 1s, 0.5s, 0s, 0s;
}

.button p:hover::after,
.home__contact__btn p:hover::after {
  width: 60%;
  height: 100%;
  right: -2px;
  box-shadow: -5px 0 0 var(--gold), -5px 0 0 var(--gold);
  transition-delay: 0s, 0.5s, 1s, 1s;
}

.button p span,
.home__contact__btn p span {
  z-index: 999;
  position: relative;
}
