* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  --banner-bg: rgba(47, 47, 47, 0.6);
  --header-nav-bg: rgb(252, 252, 252);
  --header-nav-text: rgb(53, 50, 50);
  --gold: #744d25;
  --btn-gold: #00000089;
  --grey: #bfbfbfba;
  --dark-blue: #929292;
  --review-bg: rgb(247, 247, 247);
  --heading-text: #9c9b9a;
  --nunito: "Nunito Sans", sans-serif;
  font-family: var(--nunito);
}
