.contact {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem auto;
}

.contact .contact__left__content {
  width: 70%;
}

.contact__right__content {
  width: 30%;
}
.contact__body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3rem;
}

.contact__body p {
  width: 50%;
  margin-bottom: 2rem;
}

.contact__body input {
  width: 90%;
  height: 3rem;
  text-align: center;
  background-color: var(--review-bg);
  outline: none;
  border: none;
}

.contact__body textarea {
  width: 48rem;
  height: 30vh;
  background-color: var(--review-bg);
  outline: none;
  border: none;
  resize: none;
}

.contact__right__content > div {
  display: flex;
  margin-bottom: 3rem;
  background-color: var(--review-bg);
  padding: 1rem 2rem;
}

.contact__right__content a {
  font-size: 14px;
  color: black;
  text-decoration: none;
}

.contact__btn {
  text-align: center;
}
.contact__right__content p .MuiSvgIcon-root {
  font-size: 16px;
  margin-right: 1rem;
  color: var(--gold);
}

.contact__item.socials {
  justify-content: center;
  align-items: center;
}

@media (max-width: 550px) {
  .contact {
    display: block;
  }

  .contact .contact__left__content,
  .contact .contact__right__content {
    width: 100%;
  }

  .contact__body textarea {
    width: 20rem;
  }
}
