.landscape__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGFuZHNjYXBlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 50% 50%/0 0 100% 100%; */
}

.landscape__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
}

.landscape {
  width: 90%;
  margin: auto;
}

.landscape .heading {
  margin: 2rem auto;
}

.landscape__content p {
  line-height: 30px;
  margin-bottom: 1rem;
  text-align: center;
}

.landscape__imgs {
  margin: 2rem auto;
}

.landscape__imgs img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

@media (max-width: 550px) {
  .landscape__banner {
    height: 40vh;
  }

  .landscape h3 {
    text-align: center;
    margin: 1rem 0;
  }

  .landscape__content p {
    font-size: 14px;
  }

  .landscape__header {
    font-size: 15px;
  }
}
