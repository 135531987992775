.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}

.loader__dots {
  position: relative;
  display: flex;
  animation: changeColor 5s linear infinite;
}

@keyframes changeColor {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.loader__dots .dot {
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--gold);
  margin: 20px 10px;
  border-radius: 50%;
  animation: animateDot 1.6s linear infinite;
  box-shadow: 0 0 10px var(--gold), 0 0 20px var(--gold), 0 0 40px var(--gold),
    0 0 60px var(--gold), 0 0 80px var(--gold), 0 0 100px var(--gold);
}

@keyframes animateDot {
  0% {
    transform: scale(0.1);
  }

  10% {
    transform: scale(1);
  }

  50%,
  100% {
    transform: scale(0.1);
  }
}

.loader img {
  width: 300px;
  height: 300px;
  animation: 3s loader ease infinite;
  object-fit: contain;
}

/* @keyframes loader {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
} */
