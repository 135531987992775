.projects__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/images/project.webp");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 50% 50%/0 0 100% 100%; */
}

.projects__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
}

.projects.page {
  width: 30%;
}

.projects.page:hover {
  -webkit-filter: brightness(75%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.projects.page .projects__contents {
  width: 100%;
  margin-bottom: 1rem;
}

.projects__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 95%;
  margin: auto;
}

.projects__heading {
  margin-top: 7rem;
  margin-bottom: 3rem;
  text-align: center;
}

@media (max-width: 550px) {
  .projects__banner {
    height: 30vh;
  }

  .projects__header {
    font-size: 15px;
  }

  .projects.page {
    width: 100%;
  }
}
