.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f4f4f4;
  padding: 20px;
}

.error-code {
  font-size: 120px;
  color: #e74c3c;
  margin: 0;
}

.error-message {
  font-size: 24px;
  color: #333;
  margin: 10px 0;
}

.error-description {
  font-size: 16px;
  color: #555;
  margin: 10px 0;
}

.back-home-button {
  font-size: 18px;
  color: #fff;
  background-color: #3498db;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-home-button:hover {
  background-color: #2980b9;
}
