.footer {
  background-color: #575757;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid black;
  color: white;
}

.footer > div {
  width: 50%;
  padding: 1rem;
  font-size: 14px;
}

.footer__first img {
  width: 100%;
}

.footer h3 {
  font-weight: bolder;
  margin-bottom: 3rem;
}

.footer ul {
  list-style: none;
}

.footer a {
  text-decoration: none;
  color: white;
}

.footer li {
  margin-bottom: 1rem;
}

.footer li a {
  color: white;
  text-transform: capitalize;
}

.footer li a:hover,
.footer a:hover {
  color: var(--gold);
}

.footer .MuiSvgIcon-root {
  font-size: 14px;
}

.footer .footer__fifth p {
  margin-bottom: 1rem;
}

.dev__by {
  text-align: center;
  background-color: #575757;
}

.dev__by a {
  text-decoration: none;
  color: white;
  font-weight: bolder;
}

@media (max-width: 550px) {
  .footer {
    display: block;
    text-align: center;
    padding-bottom: 1rem;
  }

  .footer > div {
    width: 100%;
    padding: 0;
  }

  .footer__first img {
    width: 70%;
  }

  .footer .footer__fifth p {
    margin-bottom: 0;
  }

  .footer h3 {
    margin-bottom: 1rem;
  }

  .nav__contact__icons__others a {
    margin: 0 25px;
  }

  .footer__contact {
    margin: 10px; /* Space around each contact section */
  }

  .footer__contact__link {
    display: inline-block;
    padding: 12px 16px;
    font-size: 16px;
    text-decoration: none;
    color: white;
    border-radius: 4px;
  }
}
