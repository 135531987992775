.banner {
  width: 100%;
  height: 70vh;
  margin-bottom: 3rem;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.banner p {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 550px) {
  .banner {
    height: 30vh;
  }
}
