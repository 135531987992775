.license {
  text-align: center;
  width: 50%;
  margin: auto;
}

.license img {
  width: 100%;
  object-fit: cover;
  margin: 10rem auto;
}

.license__banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/images/licence.webp");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 50% 50%/0 0 100% 100%; */
}

.license__header {
  position: absolute;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid white;
}

@media (max-width: 550px) {
  .license {
    width: 90%;
  }

  .license img {
    margin: 3rem auto;
  }

  .license__banner {
    height: 40vh;
  }

  .license__header {
    font-size: 15px;
  }
}
