.twitter {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 9999;
  border-radius: 50%;
  animation: twitter 0.5s ease infinite alternate;
  text-align: center;
}

@keyframes twitter {
  to {
    opacity: 0.8;
  }
}

.twitter img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: twitter 0.5s ease infinite alternate;
}

@media (max-width: 550px) {
  .twitter img {
    width: 50px;
    height: 50px;
  }
}
